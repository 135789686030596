import React,{ useEffect, useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import { useTheme } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import clsx from 'clsx';
import { Divider, IconButton, useMediaQuery } from '@material-ui/core';
import { ClearOutlined } from '@material-ui/icons';
import { useSelectStyles } from './select.style';
import { SelectHelperText, SelectSpanLabel } from './SelectHelperText';
import { capitalizeEachWord } from '../../../utils/typography.utils';

const LOADING_TEXT = 'Cargando...';
// comentado por ahora, se arregla en otro PR
// const NO_OPTIONS_TEXT = 'No hemos encontrado coxincidencias referentes a';

export default function Select({
  id,
  value = '',
  receivedValue = '',
  loading,
  onChange = () => {},
  selectedOption,
  setSelectedOption = () =>{},
  currentSearch,
  setCurrentSearch = () =>{},
  error = false,
  setError = ()=>{},
  label = '',
  className,
  placeholder = '',
  disabled,
  filterOptions,
  options = [],
  type = 'text',
  noOptionsText,
  helperText,
  isOptional,
  isRequired,
  classes: classesMui,
  classNameTextfield,
  disableClearable = false,
  disableClearOnBlur = false,
  ...autocompleteProps
}) {
  /**
   * Es necesario utilizar la funcion getOptionLabel desde el padre
   * tal que getOptionLabel={(option) => option.label}
   * donde label es el texto a mostrar como opcion
   */
  const classes = useSelectStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [ optionList, setOptionList ] = useState([]);

  const [ isFocused, setIsFocused ] = useState(false);
  const textFieldRef = useRef();

  useEffect(() => {
    if (!options) return;
    const newList = options.map((opt) => {
      return {
        ...opt,
        label: capitalizeEachWord(opt.label),
      };
    });
    setOptionList(newList);
  },[options]);

  let optionalityLabel;
  if (isOptional) {
    optionalityLabel = ' (opcional)';
  } else if (isRequired) {
    optionalityLabel = ' (requerido)';
  }

  const handleClear = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedOption('');
    setCurrentSearch('');
    setIsFocused(false);
    onChange('');
  };

  const handleAutocompleteChange = (_evt, val) => {
    setIsFocused(false);
    setSelectedOption(val);
    setCurrentSearch(val?.value?.name || '');
    onChange(val?.value || null);
  };

  const handleInputChange = (_evt, val) =>{
    setCurrentSearch(String(val));
    if(String(val).length > 1){
      setError(false);
    }
  };

  const handleBlur = () =>{
    setIsFocused(false);
    if((!currentSearch || !selectedOption) && !isOptional) {
      setError(true);
    }
    //This makes it so that when you write something and don't pick an option it erases the text.
    // If you passdown disableClearOnBlur as true this behaviour will be disabled
    if(!receivedValue && !disableClearOnBlur) {
      setCurrentSearch('');
    }
  };

  useEffect(()=>{
    if(!receivedValue && !selectedOption) {
      setCurrentSearch('');
    } else if (currentSearch) {
      const optionToSelect = options.filter(
        (option) => option.value === value || option.key === value || option.label === value
      );
      if(receivedValue && optionToSelect[0]){
        setCurrentSearch(optionToSelect[0].label);
      }else{
        setCurrentSearch('');
        setSelectedOption(null);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentSearch]);

  const handleFocusing = () =>{
    if(isFocused){
      setIsFocused(false);
    } else {
      setIsFocused(true);
      textFieldRef.current.focus();
    }
  };

  return (
    <MuiAutocomplete
      disablePortal={false}
      id={id}
      loading={loading}
      className={clsx(classes.searchBox, className)}
      classes={{
        popper: classes.popper,
        inputRoot: classes.inputRoot,
        loading: classes.loading,
        option: classes.menuItemText,
        ...classesMui
      }}
      fullWidth
      disableClearable={disableClearable}
      disabled={disabled}
      ListboxProps={{ style: { maxHeight: isMobile ? '30vh' : 300 }, position: 'bottom' }}
      loadingText={LOADING_TEXT}
      noOptionsText='No hay opciones disponibles'
      // comentado por ahora, se arregla en otro PR
      // noOptionsText={noOptionsText || `${NO_OPTIONS_TEXT} '${currentSearch}'`}
      options={optionList}
      onBlur={handleBlur}
      onFocus={handleFocusing}
      open={isFocused}
      openOnFocus
      isOptionEqualToValue={(option, val) => option.key === val || option.value === val || option.label === val}
      onChange={handleAutocompleteChange}
      onInputChange={handleInputChange}
      key={selectedOption}
      value={selectedOption ? { ...selectedOption, label: capitalizeEachWord(selectedOption.label) } : null}
      inputValue={currentSearch}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          label={<SelectSpanLabel label={label} optionalityLabel={optionalityLabel} />}
          error={error}
          variant="outlined"
          type={type}
          inputRef={textFieldRef}
          placeholder={placeholder}
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.labelFocused,
              error: classes.labelError
            },
            ...params.InputLabelProps,
          }}
          InputProps={{
            ...params.InputProps,
            classes:{
              input: classes.input,
              adornedEnd:classes.adornedEnd
            },
            endAdornment:
            <>
              {!disableClearable && selectedOption && (
                <>
                  <IconButton disabled={disabled} onClick={handleClear} className="clearButton">
                    <ClearOutlined classes={classes.clearIcon}/>
                  </IconButton>
                  <Divider orientation='vertical'/>
                </>
              )}
              <IconButton className="arrowButton" onClick={handleFocusing} disabled={disabled} error={error}>
                {isFocused ?
                  <ArrowDropUpIcon/>
                  :
                  <ArrowDropDownIcon/>
                }
              </IconButton>
            </>,
          }}
          className={classNameTextfield}
          helperText={<SelectHelperText error={error} helperText={helperText} />}
        />
      )}
      {...autocompleteProps}
    />

  );
}