import { makeStyles } from '@material-ui/core/styles';
import { PALETTE } from '../../../assets/theme';

export const useSelectStyles = makeStyles(theme => ({
  searchBox: {
    '& legend > span': {
      display: 'none',
    },
  },
  label: {
    zIndex: 30,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    right: '3rem', //do not overlap icon
    bottom: '0px', //maximize container height so label wont be cut horizontaly
    '&.MuiFormLabel-filled, &.Mui-focused': {
      color: theme.palette.grayScale.g600,
      top: '-10px',
      fontWeight: 400,
      fontSize: '19px',
      right:'-6rem',
      /*
        This is due a strange behaviour with the shrinkage of the label,
        you can't stop the shrinking nor set the size to 14px,
        i found that 19px looks like 14px
        */
    },
    '&.MuiInputLabel-outlined': {
      marginTop: '6px',
    },
    '&.MuiInputLabel-outlined.Mui-disabled, &.MuiInputLabel-outlined.Mui-error': {
      color: theme.palette.grayScale.g400,
    },
  },
  labelError:{
    color:`${PALETTE.grayScale.g400}!important`
  },
  loading: {
    color: theme.palette.grayScale.g400,
  },
  labelFocused: {
    color: `${theme.palette.secondary.main}`,
  },
  popper: {
    '& .MuiAutocomplete-paper': {
      '@media (max-width: 796px) and (min-width: 480px)': {
        marginRight: 1,
      },
      '@supports ( -moz-appearance:none )': {
        marginRight: 1
      },
      borderRadius: theme.borderRadius.large,
      boxShadow: 'none',
      border: `1px solid ${theme.palette.grayScale.g200}`,
    },
  },
  menuItemText: {
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: theme.palette.grayScale.g600,
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.5rem',
    letterSpacing: '0.02rem',
    TransformStream: 'capitalize',
    [theme.breakpoints.down('sm')] :{
      margin:0,
    }
  },
  inputRoot: {
    background: theme.palette.grayScale.g0,
    paddingTop:'0!important',
    paddingBottom:'0!important',
    paddingLeft: '0.5rem!important',
    height: '48px',
    '&.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.Mui-disabled':{
      background:theme.palette.grayScale.g01,
      '& .MuiInputAdornment-root':{
        '& svg':{
          color:theme.palette.grayScale.g400
        }
      }
    },
    zIndex: 20,
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '&.Mui-focused':{
      '& .MuiOutlinedInput-notchedOutline':{
        borderWidth:'1px'
      },
    },
    '&:hover':{
      '& .MuiOutlinedInput-notchedOutline':{
        borderColor: theme.palette.grayScale.g500
      }
    },
    //Icon
    '&.Mui-error':{
      '& span': {
        '& svg': {
          color: PALETTE.error.main,
        }
      },
    },
    '& span': {
      '& svg': {
        color: PALETTE.primary.main,
      }
    },
    '& .Mui-disabled': {
      '& span': {
        '& svg': {
          color: PALETTE.grayScale.g300,
        }
      },
    }
  },
  input:{
    fontWeight: theme.typography.weight.normal,
    fontSize: theme.typography.size.little,
    paddingRight: 0,
    '& input': {
      textOverflow:'ellipsis',
      height: '2rem',
      verticalAlign: 'center',
      padding: '0.5rem 1.25rem 0.5rem 1.25rem',
      color:theme.palette.grayScale.g500,
      '&::placeholder':{
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '20px',
        letterSpacing:'0.022em',
        color:theme.palette.grayScale.g400
      },
      '&:disabled': {
        color: theme.palette.grayScale.g400,
        zIndex: 1
      },
    },
  },
  adornedEnd: {
    '& .clearButton':{
      maxHeight:'15px',
      width:'15px',
      // padding:'0!important',
      '& svg':{
        color: theme.palette.grayScale.g400,
      },
      '&:disabled svg':{
        color: theme.palette.grayScale.g400
      }
    },

    '& .arrowButton':{
      maxHeight:'15px',
      width:'15px',
      '&:disabled svg':{
        color: theme.palette.grayScale.g400
      },
      '&:error svg':{
        // color:'red'
      }
    },
    '& hr':{
      height:'24px',
      margin: '0 0.75rem',
      alignSelf:'center'
    },
    paddingRight: '0.5rem!important'
  },
  clearIcon:{
    '& .MuiIconButton-root.Mui-disabled':{
      background:'red',
      color:'red!important'
    }
  },
}));
