import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { PALETTE } from '../../../assets/theme';
import { IconButton, InputAdornment, useMediaQuery } from '@material-ui/core';
import { ClearOutlined } from '@material-ui/icons';

const LOADING_TEXT = 'Cargando...';
const NO_OPTIONS_TEXT = 'No hemos encontrado coincidencias referentes a';
const useStyles = makeStyles(theme => ({
  loading: {
    color: theme.palette.grayScale.g400,
  },
  popper: {
    '& .MuiAutocomplete-paper': {
      '@media (max-width: 796px) and (min-width: 480px)': {
        marginRight: 1,
      },
      '@supports ( -moz-appearance:none )': {
        marginRight: 1
      },
      borderRadius: theme.borderRadius.large,
      boxShadow: 'none',
      border: `1px solid ${theme.palette.grayScale.g200}`,
    },
  },
  menuItemText: {
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: theme.palette.grayScale.g400,
    fontSize: theme.typography.size.smaller,
    [theme.breakpoints.down('sm')] :{
      margin:0,
    }
  },
  inputRoot: {
    '&.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.Mui-disabled':{
      background: theme.palette.grayScale.g01,
      '& .MuiInputAdornment-root':{
        '& svg':{
          color: theme.palette.grayScale.g400
        }
      }
    },
    paddingTop:'0!important',
    paddingBottom:'0!important',
    paddingLeft: '0.5rem!important',
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    //Icon
    '&.Mui-error':{
      '& span': {
        '& svg': {
          color: PALETTE.error.main,
        }
      },
    },
    '& span': {
      '& svg': {
        color: PALETTE.primary.main,
      }
    },
    '&.Mui-focused':{
      '& .MuiOutlinedInput-notchedOutline':{
        borderWidth:'1px'
      }
    },

  },
  searchButton: {
    border: 'none',
    background: 'none',
    marginLeft: '3px',
    padding: 0,
    cursor: 'pointer'
  },
  input:{
    color: 'black',
    height: '32px',
    width: '100%!important',
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&::placeholder':{
      color: theme.palette.grayScale.g400,
      fontWeight: 400,
    },
  },
  adornedStart: {
    color: theme.palette.grayScale.g400,
    paddingLeft: '1.5rem!important',
  },
  adornedEnd: {
    '& button':{
      maxHeight:'15px',
      width:'15px',
      '& svg':{
        color: theme.palette.grayScale.g400,
      },
    },
    paddingRight: '1.5rem!important'
  }
}));

export default function SearchBar({
  id,
  receivedValue = '',
  setValue,
  currentSearch = '',
  selectedOption,
  onFocus,
  onBlur,
  loading,
  onReload = () => {},
  onChange = () => {},
  onChangeInput = () => {},
  onKeyDownInput = () => {},
  placeholder = 'Placeholder',
  className,
  disabled,
  options = [],
  type = 'text',
  noOptionsText = `${NO_OPTIONS_TEXT} '${currentSearch}'`,
  classes: classesMui,
  classNameTextfield,
  filterOptions,
  error,
  disableClearable = false,
  autocomplete = true,
  ...autocompleteProps
}) {
  /**
   * Es necesario utilizar la funcion getOptionLabel desde el padre
   * tal que getOptionLabel={(option) => option.label}
   * donde label es el texto a mostrar como opcion
   */

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleAutocompleteChange = (_evt, val) => {
    onChange(val);
    if(!val) return onChange('');
  };

  const handleInputChange = (_evt, val) =>{
    onChangeInput(val);
    if(!val) return onChangeInput('');
  };

  useEffect(()=>{
    onReload();
  });
  const handleFocus = () => {
    onFocus();
  };
  const handleBlur = () => {
    onBlur();
  };
  const handleClear = () => {
    if(disabled) return;
    onChange('');
    onChangeInput('');
  };

  if (!autocomplete) {
    return (
      <TextField
        fullWidth
        error={error}
        variant="outlined"
        placeholder={placeholder}
        type={type}
        value={receivedValue}
        disableClearable={disableClearable}
        onChange={onChangeInput}
        onKeyDown={onKeyDownInput}
        id={id}
        InputProps={{
          classes: {
            input: classes.input,
            adornedStart: classes.adornedStart,
            adornedEnd: classes.adornedEnd
          },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: receivedValue && (
            <IconButton onClick={handleClear}>
              <ClearOutlined/>
            </IconButton>
          )
        }}
        className={classNameTextfield}
      />
    );
  }

  return (
    <MuiAutocomplete
      disablePortal={false}
      id={id}
      loading={loading}
      className={clsx(classes.searchBox, className)}
      classes={{
        popper: classes.popper,
        inputRoot: classes.inputRoot,
        loading: classes.loading,
        option: classes.menuItemText,
        ...classesMui
      }}
      fullWidth
      disableClearable={disableClearable}
      disabled={disabled}
      ListboxProps={{ style: { maxHeight: isMobile ? '30vh' : 300 }, position: 'bottom' }}
      loadingText={LOADING_TEXT}
      noOptionsText={noOptionsText}
      options={options}
      onBlur={handleBlur}
      onFocus={handleFocus}
      isOptionEqualToValue={(option, value) => option.key === value || option.value === value || option.label === value}
      onChange={handleAutocompleteChange}
      onInputChange={handleInputChange}
      value={receivedValue}
      inputValue={receivedValue}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          variant="outlined"
          defaultValue={receivedValue}
          placeholder={placeholder}
          type={type}
          InputProps={{
            ...params.InputProps,
            classes:{
              input: classes.input,
              adornedStart: classes.adornedStart,
              adornedEnd: classes.adornedEnd
            },
            startAdornment:(
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),

            endAdornment: receivedValue && (
              <IconButton onClick={handleClear}>
                <ClearOutlined/>
              </IconButton>
            )
          }}
          className={classNameTextfield}
        />
      )}
      {...autocompleteProps}
    />
  );
}
