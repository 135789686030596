import React from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CustomTypography from '../CustomTypography.component';
import { NEW_PALETTE } from '../../../assets/theme';

export const SelectSpanLabel = ({ label, optionalityLabel }) => <span>{label}
  <span style={{ color: NEW_PALETTE.neutral.gray05, fontWeight: 400 }}>{optionalityLabel}</span>
</span>;

export const SelectHelperText = ({ error, helperText }) =>{
  if(error && helperText) {
    return (
      <span
        style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}
      >
        <ErrorOutlineIcon
          style={{ fontSize: '14px' }} /><CustomTypography variant="body2" customColor="error">
          {helperText}
        </CustomTypography>
      </span>);
  }
  return <CustomTypography variant="body2">{helperText}</CustomTypography>;
};
